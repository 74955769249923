import { useCallback } from "react";

import { useRx } from "@/contexts/rx";
import { withExactMessage } from "@/util/error";
import { displayLastError } from "@/util/error";

export const useDisplayError = (reset?: () => void) => {
  const { error$ } = useRx();

  const lastError = useCallback(
    (suppress?: boolean) => {
      if (!suppress) {
        displayLastError(error$);
      }

      reset?.();
      // explicit undefined return to avoid mess with types
      return undefined;
    },
    [error$, reset],
  );

  const withMessage = useCallback(
    (msg: string, imgSrc?: string) => {
      error$.next(withExactMessage(Error(msg), imgSrc));
      reset?.();
      // explicit undefined return to avoid mess with types
      return undefined;
    },
    [error$, reset],
  );

  return { lastError, withMessage };
};
