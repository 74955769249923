export const GAS_APPROVE = BigInt(1_000_000);
export const GAS_CLOSE = BigInt(1_000_000);
export const GAS_DEPOSIT_BASE = BigInt(1_500_000);
export const GAS_DEPOSIT_QUOTE = BigInt(1_500_000);
export const GAS_LONG = BigInt(1_000_000);
export const GAS_SHORT = BigInt(1_000_000);
export const GAS_WETH_WRAP = BigInt(1_000_000);
export const GAS_WETH_UNWRAP = BigInt(1_000_000);
export const GAS_WITHDRAW_BASE = BigInt(2_000_000);
export const GAS_WITHDRAW_QUOTE = BigInt(2_000_000);
export const GAS_PENDLE_SWAP = BigInt(2_000_000);
