import type { FC, MouseEvent } from "react";
import { jt, t } from "ttag";

import Space from "@/components/common/space";
import { Noop } from "@/components/util";

import {
  TradeActionButton,
  TradePendingAnimation,
  TradePendingAnimation2,
  TradePendingBody,
  TradePendingContainer,
  TradePendingHeading,
  TradePendingWrapper,
} from "../trade.styled";

const br = <br key="br" />;

export enum PendingTxType {
  Approved = 1,
  AwaitingApprove,
  Pending,
  Network,
}

const getHeading = (type: PendingTxType) => {
  switch (type) {
    case PendingTxType.Approved:
      return t`The spending is approved`;
    case PendingTxType.AwaitingApprove:
      return jt`Approve the spending${br}in your wallet first`;
    case PendingTxType.Pending:
      return t`Sign the message in your wallet`;
    case PendingTxType.Network:
    default:
      return t`Approve the action in wallet`;
  }
};

const PendingTxPartial: FC<{
  cancel?: () => void;
  submit?: () => void;
  title?: string;
  type: PendingTxType;
}> = ({ cancel, submit, title, type }) => (
  <TradePendingWrapper onClick={cancel}>
    <TradePendingBody
      onClick={(e: MouseEvent) => e.stopPropagation()}
      style={
        ![PendingTxType.Pending, PendingTxType.Network].includes(type)
          ? { paddingBottom: 24 }
          : undefined
      }
    >
      {[PendingTxType.Pending, PendingTxType.Network].includes(type) ? (
        <TradePendingContainer>
          <TradePendingAnimation />
          <TradePendingAnimation2 />
        </TradePendingContainer>
      ) : type === PendingTxType.AwaitingApprove ? (
        <img src="/images/approve-lock.svg" alt="" />
      ) : (
        <img src="/images/approved.svg" alt="" />
      )}

      <Space
        height={
          ![PendingTxType.Pending, PendingTxType.Network].includes(type)
            ? 40
            : 16
        }
        heightMobile={
          ![PendingTxType.Pending, PendingTxType.Network].includes(type)
            ? 40
            : 16
        }
      />

      <TradePendingHeading>{getHeading(type)}</TradePendingHeading>

      <Space
        height={type !== PendingTxType.Pending ? 20 : 12}
        heightMobile={type !== PendingTxType.Pending ? 20 : 12}
      />

      <div>
        {[PendingTxType.Pending, PendingTxType.Network]
          ? title
          : type === PendingTxType.AwaitingApprove
          ? t`After that, you can continue.`
          : t`Now you can continue.`}
      </div>

      {![PendingTxType.Pending, PendingTxType.Network].includes(type) ? (
        <>
          <Space height={48} heightMobile={48} />

          <TradeActionButton onClick={submit} className="approve">
            <span className="overlay">{t`Continue`}</span>
          </TradeActionButton>
        </>
      ) : (
        <Noop />
      )}
    </TradePendingBody>
  </TradePendingWrapper>
);

export default PendingTxPartial;
