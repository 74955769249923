import { useCallback, useRef, useState } from "react";
import { ContractFunctionExecutionError } from "viem";
import {
  arbitrum,
  arbitrumGoerli,
  arbitrumSepolia,
  mainnet,
} from "viem/chains";
import { useAccount } from "wagmi";

import { x1Testnet } from "@/constants/chains";
import { useRx } from "@/contexts/rx";
import { withHidden } from "@/util/error";

const ignoreGas = (gas: bigint, chainId?: number) =>
  chainId &&
  [
    mainnet.id,
    arbitrum.id,
    arbitrumGoerli.id,
    arbitrumSepolia.id,
    x1Testnet.id,
  ].includes(chainId as any)
    ? undefined
    : gas;

/** @deprecated rename as it also has some kind of error handlers */
export const useGas = (defaultGas: bigint = BigInt(50_000)) => {
  const { error$ } = useRx();
  let err = useRef<Error>();
  const [_gas, setGas] = useState(defaultGas);
  // todo wtf with gas on different networks?
  const { chain } = useAccount();
  const gas = ignoreGas(_gas, chain?.id);

  const useError = useCallback(
    (error?: Error | null) => {
      if (error && error !== err.current) {
        if (error instanceof ContractFunctionExecutionError) {
          const errCode =
            (error.cause.cause as undefined | { name: string })?.name ??
            error.cause.name;

          if (errCode === "IntrinsicGasTooLowError") {
            setGas((gas) => {
              const nextGas = gas * BigInt(2);
              console.warn("increased gas limit to:" + nextGas.toString());
              return nextGas;
            });
          } else {
            setTimeout(() => error$.next(withHidden(error, true)));
          }
        }

        err.current = error;
      }
    },
    [error$],
  );

  return {
    gas,
    gasPrice: undefined,
    useError,
  };
};
