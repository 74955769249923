export enum CallType {
  DepositBase,
  DepositQuote,
  WithdrawBase,
  WithdrawQuote,
  Short,
  Long,
  ClosePosition,
  Reinit,
  ReceivePosition,
  EmergencyWithdraw,
}

export const enum SwapRouter {
  Marginly = 0,
  Portals,
  Reserved10 = 10,
  KyberSwap = 11,
}
