import { type Chain, numberToHex } from "viem";

import { markWritable } from "@/util/core";

const ADD_ETH_CHAIN_METHOD = "wallet_addEthereumChain";
const SWITCH_ETH_CHAIN_METHOD = "wallet_switchEthereumChain";
const WATCH_ASSET_METHOD = "wallet_watchAsset";

const addChainRequest = (chain: Chain) =>
  markWritable({
    method: ADD_ETH_CHAIN_METHOD,
    params: [
      {
        chainId: numberToHex(chain.id),
        blockExplorerUrls: [chain.blockExplorers?.default?.url],
        chainName: chain.name,
        nativeCurrency: chain.nativeCurrency,
        rpcUrls: [...chain.rpcUrls.default.http],
      },
    ],
  } as const);

const switchChainRequest = (chain: Chain) =>
  markWritable({
    method: SWITCH_ETH_CHAIN_METHOD,
    params: [
      {
        chainId: numberToHex(chain.id),
      },
    ],
  } as const);

export const chainRequest = (chain: Chain) => {
  return chain.id === 1 ? switchChainRequest(chain) : addChainRequest(chain);
};

export const watchAssetRequest = (options: {
  address: `0x${string}`;
  symbol: string;
  decimals: number;
  image: string;
}) =>
  markWritable({
    method: WATCH_ASSET_METHOD,
    params: {
      type: "ERC20",
      options,
    },
  } as const);
