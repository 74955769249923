"use client";
import Button from "@marginly/ui/components/button";
import Noop from "@marginly/ui/components/helpers/noop";
import { L, Pending } from "@marginly/ui/constants/classnames";
import cn from "classnames";
import type { ReactNode } from "react";
import type { MouseEvent } from "react";
import { useState } from "react";
import { t } from "ttag";
import { useAccount, useChainId, useDisconnect, useWalletClient } from "wagmi";

import { SUPPORTED_CHAINS } from "@/constants/chains";
import { useRx } from "@/contexts/rx";
import { chainRequest } from "@/util/wallet";

import FailedSwitchModal from "../trade/partials/failed-switch-modal";
import PendingTxPartial, { PendingTxType } from "../trade/partials/pending-tx";
import SwitchNetworkModal from "../trade/partials/switch-network-modal";
import ClientOnly from "../util/client-only";
import Portal from "./portal";

interface Props {
  chainName: string;
  children: ReactNode;
}

// interface RequestArguments {
//   method: string;
//   params?: unknown[] | object;
// }

// interface RequestProvider {
//   // make an Ethereum RPC method call.
//   request(args: RequestArguments): Promise<unknown>;
// }

// todo make stable implementation
/** reference - walletconnect connector switch chain implementation
 async switchChain(chainId: number) {
    const chain = this.chains.find((chain) => chain.id === chainId)
    if (!chain)
      throw new SwitchChainError(new Error('chain not found on connector.'))

    try {
      const provider = await this.getProvider()
      const namespaceChains = this.#getNamespaceChainsIds()
      const namespaceMethods = this.#getNamespaceMethods()
      const isChainApproved = namespaceChains.includes(chainId)

      if (!isChainApproved && namespaceMethods.includes(ADD_ETH_CHAIN_METHOD)) {
        await provider.request({
          method: ADD_ETH_CHAIN_METHOD,
          params: [
            {
              chainId: numberToHex(chain.id),
              blockExplorerUrls: [chain.blockExplorers?.default?.url],
              chainName: chain.name,
              nativeCurrency: chain.nativeCurrency,
              rpcUrls: [...chain.rpcUrls.default.http],
            },
          ],
        })
        const requestedChains = this.#getRequestedChainsIds()
        requestedChains.push(chainId)
        this.#setRequestedChainsIds(requestedChains)
      }
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: numberToHex(chainId) }],
      })

      return chain
    } catch (error) {
      const message =
        typeof error === 'string' ? error : (error as ProviderRpcError)?.message
      if (/user rejected request/i.test(message)) {
        throw new UserRejectedRequestError(error as Error)
      }
      throw new SwitchChainError(error as Error)
    }
  }
 */

const ADD_ETH_CHAIN_METHOD = "wallet_addEthereumChain";
const ERR_CODE_METHOD_NOT_SUPPORTED = -32601;
const ERR_CODE_WRONG_TOKEN = -32602;

export default function SwitchNetworkOr({ chainName, children }: Props) {
  const { disconnectAsync } = useDisconnect();
  const { chainId } = useAccount();
  const { error$, notification$ } = useRx();
  const [pending, setPending] = useState(false);
  const [showSwitchMoodal, setShowSwitchModal] = useState(false);
  const [tryReconnect, setTryReconnect] = useState(false);
  const chain = SUPPORTED_CHAINS[chainName as keyof typeof SUPPORTED_CHAINS];

  function disconnect(e: MouseEvent) {
    e.preventDefault();
    setPending(true);

    disconnectAsync()
      .then(() => {
        setPending(false);
        setTryReconnect(false);
      })
      .catch((err) => {
        error$.next(err);
        setPending(false);
      });
  }

  const walletClient = useWalletClient();

  async function switchNetwork(e: MouseEvent) {
    e.preventDefault();
    const provider = walletClient.data;

    if (chain) {
      setPending(true);
      setShowSwitchModal(false);

      function triggerFallback() {
        setTryReconnect(true);

        notification$.next({
          message: `Failed to switch network. Try reconnecting your wallet or adding network manually.`,
        });

        setPending(false);
      }

      // async function trySwitchChain() {
      //   try {
      //     await provider?.request(
      //       // @ts-expect-error
      //       chainRequest(chain),
      //     );

      //     setPending(false);
      //   } catch (err) {
      //     triggerFallback();
      //     console.error(err);
      //   }
      // }

      try {
        await provider?.request(
          // @ts-expect-error
          chainRequest(chain),
        );

        setPending(false);
      } catch (_err) {
        const err: Error & { code?: number } = _err as any;
        console.error(err);
        error$.next(err);
        setPending(false);

        // if (err.code === ERR_CODE_METHOD_NOT_SUPPORTED) {
        //   triggerFallback();
        // } else if (err.code === ERR_CODE_WRONG_TOKEN) {
        //   await trySwitchChain();
        // } else {
        //   console.error(err);
        //   error$.next(err);
        //   setPending(false);
        // }
      }
    }
  }
  return (
    <ClientOnly
      fallback={
        <Button style={{ width: "100%" }} className={cn(L, Pending)}>
          &nbsp;
        </Button>
      }
    >
      {chainId === chain?.id ? (
        children
      ) : tryReconnect ? (
        <FailedSwitchModal
          disconnect={disconnect}
          cancel={() => {
            setShowSwitchModal(false);
          }}
        />
      ) : (
        <div
          onClickCapture={(e) => {
            setShowSwitchModal(true);
            e.stopPropagation();
          }}
          style={{ display: "grid" }}
        >
          {children}
        </div>
      )}
      {showSwitchMoodal ? (
        <SwitchNetworkModal
          switchTo={chain?.name}
          handleSwitch={switchNetwork}
          cancel={() => {
            setShowSwitchModal(false);
          }}
        />
      ) : (
        <Noop />
      )}

      {pending ? (
        <Portal>
          <PendingTxPartial
            type={PendingTxType.Network}
            title={t`Switch to ${chain?.name}`}
          />
        </Portal>
      ) : (
        <Noop />
      )}
    </ClientOnly>
  );
}
