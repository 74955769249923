"use client";
import Button from "@marginly/ui/components/button";
import T from "@marginly/ui/components/typography";
import type { FC, MouseEvent } from "react";
import styled from "styled-components";

import Space from "@/components/common/space";

import {
  TradePendingBody,
  TradePendingHeading,
  TradePendingWrapper,
} from "../trade.styled";

const Alert = styled.img`
  width: 96px;
  height: 96px;
  @media (max-width: 1024px) {
    width: 64px;
    height: 64px;
  }
`;

const MobileSwitcher = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const br = <br key="br" />;

const FailedSwitchModal: FC<{
  cancel?: () => void;
  disconnect: (e: React.MouseEvent) => void;
}> = ({ cancel, disconnect }) => {
  return (
    <MobileSwitcher>
      <TradePendingWrapper onClick={cancel}>
        <TradePendingBody
          onClick={(e: MouseEvent) => e.stopPropagation()}
          style={{ paddingBottom: "24px" }}
        >
          <Alert src="/images/unsuccess.svg" alt="" />
          <Space height={40} heightMobile={40} />
          <TradePendingHeading>
            Unsuccessful switching{br}the network
          </TradePendingHeading>
          <Space height={8} heightMobile={8} />
          <T body>
            Please disconnect or try manually switch to Arbitrum Goerli in the
            wallet.
          </T>
          <Space height={48} heightMobile={48} />

          <Button lg onClick={disconnect} style={{ width: "100%" }}>
            <T action>Disconnect</T>
          </Button>
          <Space height={8} heightMobile={8} />
          <Button lg secondary onClick={close} style={{ width: "100%" }}>
            <T action>Close</T>
          </Button>
        </TradePendingBody>
      </TradePendingWrapper>
    </MobileSwitcher>
  );
};

export default FailedSwitchModal;
