import T from "@marginly/ui/components/typography";
import cn from "classnames";
import type { FC, MouseEvent } from "react";
import styled from "styled-components";
import { t } from "ttag";

import Portal from "@/components/common/portal";
import Space from "@/components/common/space";

import {
  TradeActionButton,
  TradePendingBody,
  TradePendingHeading,
  TradePendingWrapper,
} from "../trade.styled";

const Alert = styled.img`
  width: 96px;
  height: 96px;
  @media (max-width: 1024px) {
    width: 64px;
    height: 64px;
  }
`;

const br = <br key="br" />;

const SwitchNetworkModal: FC<{
  cancel?: () => void;
  switchTo: string;
  handleSwitch: (e: React.MouseEvent) => void;
}> = ({ cancel, switchTo, handleSwitch }) => {
  return (
    <Portal>
      <TradePendingWrapper onClick={cancel}>
        <TradePendingBody
          onClick={(e: MouseEvent) => e.stopPropagation()}
          style={{ paddingBottom: "24px" }}
        >
          <Alert src="/images/alert-round.svg" alt="" />
          <Space height={40} heightMobile={40} />
          <TradePendingHeading>
            Wrong network{br}in&nbsp;the&nbsp;wallet
          </TradePendingHeading>
          <Space height={8} heightMobile={8} />
          <T body>
            Your wallet&apos;s network doesn&apos;t match
            app&apos;s&nbsp;network.
          </T>
          <Space height={48} heightMobile={48} />

          <TradeActionButton
            onClick={handleSwitch}
            className={cn("approve", {})}
          >
            <span className="overlay">
              {t`Switch to`}&nbsp;{switchTo}
            </span>
          </TradeActionButton>
        </TradePendingBody>
      </TradePendingWrapper>
    </Portal>
  );
};

export default SwitchNetworkModal;
